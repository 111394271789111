import { defineStore } from 'pinia';
import Constants from '../utils/constants';
import type { LoginReq, OtpReq, RegisterReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';

export const useAuthStore = defineStore({
	id: 'authStore',
	state: () => ({
		accessToken: '' as string | undefined,
		refreshToken: '' as string | undefined,
		countryCode: '60' as string,
		phoneNum: '' as string,
		loading: false as boolean,
		errors: [] as string[],
	}),
	actions: {
		setCountryCode(code: string) {
			this.countryCode = code;
		},
		// login
		async login(otp: string) {
			// fetch login from api
			try {
				const request: LoginReq = {
					country_code: this.countryCode,
					contact: this.phoneNum.replace('-', ''),
					otp_code: otp,
				};
				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.auth.login(request);

				// set user
				this.accessToken = response?.access_token;
				console.log(this.accessToken);

				const userStore = useUserStore();
				if (response?.user !== undefined && response?.user !== null) {
					userStore.setUser(response?.user);
				}

				// if (this.accessToken !== undefined && this.accessToken !== null) {
				// 	localStorage.setItem(Constants.ACCESS_TOKEN, this.accessToken);
				// }

				return true;
			} catch (err: any) {
				// console.log(err);
				if (err.data.code == 100038) {
					// registered user, nav user to register page
					throw err.data;
				}

				throw err;
			}
		},
		// logout
		logout() {
			// logout logic here
			localStorage.removeItem(Constants.ACCESS_TOKEN);
			this.accessToken = '';
			this.refreshToken = '';
			this.errors = [];
			this.loading = false;

			const userStore = useUserStore();
			userStore.setUser(null);
		},

		// social login
		socialLogin() {
			// social login logic here
		},

		// reset password
		resetPassword() {
			// reset password logic here
		},
		// change password
		changePassword() {
			// change password logic here
		},
		// otp login
		async otpLogin(phone: string) {
			// otp login logic here
			this.loading = true;
			try {
				const request: OtpReq = {
					contact: phone.replace('-', ''),
					country_code: this.countryCode,
				};

				const $api = useNuxtApp().$api as IApiInstance;
				await $api.auth.requestOTP(request);

				this.phoneNum = phone;
				return true;
			} catch (error) {
				console.log(error);
				return false;
			} finally {
				this.loading = false;
			}
		},
		// verify otp
		verifyOtp() {
			// verify otp logic here
		},

		// register
		async register(data: any) {
			const request: RegisterReq = {
				name: data.name,
				email: data.email,
				country_code: this.countryCode,
				contact: data.phoneNum.replace('-', ''),
				dob: data.dob,
				source: 'web',
			};

			this.loading = true;
			let result = false;
			try {
				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.auth.register(request);
				// const response = await new ApiService().fetchRegisterApi(body);

				this.accessToken = response!.access_token;

				// if (this.accessToken !== undefined) {
				// 	localStorage.setItem(Constants.ACCESS_TOKEN, this.accessToken);
				// }

				const userStore = useUserStore();
				if (response!.user !== undefined && response!.user !== null) {
					userStore.setUser(response!.user);
				}

				result = true;
			} catch (error: any) {
				result = false;
				console.error(error!.data!.message);
				this.errors = [];
				this.errors.push(error!.data!.message);
				return result;
			} finally {
				this.loading = false;
			}
		},

		async initApp() {
			if (import.meta.client) {
				const accessToken = localStorage.getItem(Constants.ACCESS_TOKEN);
				if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
					this.accessToken = accessToken;
					const $api = useNuxtApp().$api as IApiInstance;
					await $api.auth.setToken(accessToken);
				}
			}
		},
	},
	getters: {
		// isLogged in
		isLoggedIn(): boolean {
			return this.accessToken !== null && this.accessToken !== undefined && this.accessToken !== '';
		},

		getLoadingState(): boolean {
			return this.loading;
		},

		getErrors(): string[] {
			return this.errors;
		},

		isAuthorised(): boolean {
			return this.accessToken !== null && this.accessToken !== undefined && this.accessToken !== '';
		},
	},
});
